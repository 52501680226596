import axios from "axios";
import "../../common/axiosInterceptor/axios-interceptor";

export const GetWardBeds = async (wardId) => {
    let config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_BASE_URL}/admindashboard/api/getWardBeds`,
        authRequired: true,
        data: {
            "wardId": wardId
        }
    };

    console.log(config); 
     
    

    return axios.request(config);
}
