import { GetWardBeds } from '../adapters/Beds/GetWardBeds';
import { addBed } from '../adapters/Beds/AddBed';

// Function to fetch the beds for a specific ward
export const fetchWardBeds = async (wardId) => {
  return new Promise((resolve, reject) => {
    console.log(`Fetching beds for wardId: ${wardId}`); // Log the wardId
    GetWardBeds(wardId) // Pass the wardId to the API call
      .then((res) => {
        console.log("Beds API Response:", res); // Log the entire response
        if (res && res.data && (res.status === 200 || res.status === 201)) {
          console.log("Successful Bed Data:", res.data); // Log the resolved data
          resolve(res.data); // Resolve the promise with bed data
        } else {
          console.error("Unexpected API Response:", res);
          reject(res?.data?.error?.message || "Error fetching bed details");
        }
      })
      .catch((err) => {
        console.error("API Error Details:", err?.response?.data || err.message);
        reject("Unexpected error occurred while fetching beds.");
      });
  });
};

// Function to add a new bed to a specific ward
export const AddBed = (data) => {
  return new Promise((resolve, reject) => {
    console.log("Adding Bed with Data:", data);

    addBed(data)
      .then((res) => {
        if (res && res.data && (res.status === 200 || res.status === 201)) {
          resolve(res.data); // Resolve with success data
        } else {
          console.error("API Response Error:", res);
          reject(res?.data?.error?.message || "Unexpected error occurred while adding the bed.");
        }
      })
      .catch((err) => {
        console.error("API Error:", err);
        reject("Unexpected error occurred while adding the bed.");
      });
  });
};
