import { GetBedMachines } from "./../adapters/Devices/GetBedMachines";
import { addVentilator } from "../adapters/Devices/AddVentilator";
import { addPatientMonitor } from "../adapters/Devices/AddPatientMonitor";
import { updateBedDevice } from "../adapters/Devices/UpdateBedDevice"; // Import the adapter for updateBedDevice
import { FindDeviceById } from "../adapters/Devices/FindDevice";

export const fetchBedMachines = async (bedId) => {
  return new Promise((resolve, reject) => {
    console.log(`Fetching devices for bedId: ${bedId}`); // Log the bedId
    GetBedMachines(bedId)
      .then((res) => {
        console.log("Devices API Response:", res); // Log the entire response
        if (res && res.data && (res.status === 200 || res.status === 201)) {
          console.log("Successful Device Data:", res.data); // Log the resolved data
          resolve(res.data.data); // Resolve the promise with bed data
        } else {
          console.error("Unexpected API Response:", res);
          reject(res?.data?.error?.message || "Error fetching device details");
        }
      })
      .catch((err) => {
        console.error("API Error Details:", err?.response?.data || err.message);
        reject("Unexpected error occurred while fetching devices.");
      });
  });
};

export const AddVentilator = (data) => {
  return new Promise((resolve, reject) => {
    console.log("Adding Ventilator with Data:", data);

    addVentilator(data)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          resolve(res.data.data); // Resolve with success data
        } else {
          console.error("API Response Error:", res);
          reject(res?.data?.error?.message || "Unexpected error occurred while adding the ventilator.");
        }
      })
      .catch((err) => {
        console.error("API Error:", err);
        reject("Unexpected error occurred while adding the ventilator.");
      });
  });
};

export const AddPatientMonitor = (data) => {
  return new Promise((resolve, reject) => {
    console.log("Adding Patient Monitor with Data:", data);

    addPatientMonitor(data)
      .then((res) => {
        if (res && res.data && (res.status === 200 || res.status === 201)) {
          resolve(res.data.data); // Resolve with success data
        } else {
          console.error("API Response Error:", res);
          reject(res?.data?.error?.message || "Unexpected error occurred while adding the patient monitor.");
        }
      })
      .catch((err) => {
        console.error("API Error:", err);
        reject("Unexpected error occurred while adding the patient monitor.");
      });
  });
};

export const UpdateBedDevice = (bedId, monitorId, ventilatorId ) => {
  return new Promise((resolve, reject) => {
    console.log(`Updating Bed Device for bedId: ${bedId}`);

    updateBedDevice(bedId, monitorId, ventilatorId)
      .then((res) => {
        console.log("updateBedDevice res", res);
        if (res && (res.status === 200 || res.status === 201)) {
          console.log("Bed Device updated successfully:", res.data);
          resolve(res.data); 
        } else {
          console.error("API Response Error:", res);
          reject(res?.data?.error?.message || "Unexpected error occurred while updating the bed device.");
        }
      })
      .catch((err) => {
        console.error("API Error:", err);
        reject("Unexpected error occurred while updating the bed device.");
      });
  });
};


// Find Ventilator by ID
export const FindVentilator = (ventilatorId) => {
  return new Promise((resolve, reject) => {
    console.log(`Searching for Ventilator ID: ${ventilatorId}`);

    FindDeviceById({ ventilatorId }) // Send as an object
      .then((res) => {
        console.log("Find Ventilator API Response:", res);
        if (res && res.data.success) {
          console.log("Ventilator Found at Bed ID:", res.data.bedId);
          resolve(res.data.bedId);
        } else {
          console.warn("Ventilator not found");
          reject("Ventilator ID not found on any bed.");
        }
      })
      .catch((err) => {
        console.error("API Error:", err);
        reject("Unexpected error occurred while searching for the ventilator.");
      });
  });
};

//  Find Patient Monitor by ID
export const FindPatientMonitor = (monitorId) => {
  return new Promise((resolve, reject) => {
    console.log(`Searching for Monitor ID: ${monitorId}`);

    FindDeviceById({ monitorId }) // Send as an object
      .then((res) => {
        console.log("Find Monitor API Response:", res);
        if (res && res.data.success) {
          console.log("Monitor Found at Bed ID:", res.data.bedId);
          resolve(res.data.bedId);
        } else {
          console.warn("Monitor not found");
          reject("Monitor ID not found on any bed.");
        }
      })
      .catch((err) => {
        console.error("API Error:", err);
        reject("Unexpected error occurred while searching for the monitor.");
      });
  });
};