import { GetHospitalWards } from '../adapters/Ward/GetHospitalWards';
import { addWard } from '../adapters/Ward/AddWard';


// Function to fetch the ward list or details for a specific ward
export const fetchHospitalWards = async (hospitalId) => {
  return new Promise((resolve, reject) => {
    console.log(`Fetching wards for hospitalId: ${hospitalId}`); // Log the hospitalId
    GetHospitalWards(hospitalId) // Pass the hospitalId to the API call
      .then((res) => {
        console.log("Ward API Response:", res); // Log the entire response
        if (res && res.data && (res.status === 200 || res.status === 201)) {
          console.log("Successful Ward Data:", res.data); // Log the resolved data
          resolve(res.data); // Resolve the promise with ward data
        } else {
          console.error("Unexpected API Response:", res);
          reject(res?.data?.error?.message || "Error fetching ward details");
        }
      })
      .catch((err) => {
        console.error("API Error Details:", err?.response?.data || err.message);
        reject("Unexpected error occurred while fetching wards.");
      });
  });
};



// Function to add a new ward
export const AddWard = (data) => {
  return new Promise((resolve, reject) => {
    console.log("Adding Ward with Data:", data); 

    addWard(data)
      .then((res) => {
        if (res && res.data && (res.status === 200 || res.status === 201)) {
          resolve(res.data); // Resolve with success data
        } else {
          console.error("API Response Error:", res);
          reject(res?.data?.error?.message || "Unexpected error occurred while adding the ward.");
        }
      })
      .catch((err) => {
        console.error("API Error:", err);
        reject("Unexpected error occurred while adding the ward.");
      });
  }); 
};
